angular.module('app')
  .factory('AuteurbiblioEditModal', AuteurbiblioEditModal);

/*@ngInject*/
function AuteurbiblioEditModal($q, $rootScope, $modal, $window, AuteurbiblioService) {
  return { createAuteurbiblio, updateAuteurbiblio };

  function createAuteurbiblio(uniqueId) {
    openModal(null)
      .then((auteurbiblioId) => {
        // TODO: A remplacer par un événement géré par AuteurbiblioService dans le cas où le template englobant est Angular et non Handlebars
        $rootScope.$broadcast('auteurbiblio.edit.success', uniqueId, auteurbiblioId);
      });
  }

  function updateAuteurbiblio(uniqueId, auteurbiblio) {
    openModal(auteurbiblio.id)
      .then((auteurbiblioId) => {
        // TODO: A remplacer par un événement géré par AuteurbiblioService dans le cas où le template englobant est Angular et non Handlebars
        $rootScope.$broadcast('auteurbiblio.edit.success', uniqueId, auteurbiblioId);
      });
  }

  function openModal(auteurbiblioId) {
    return $modal.open({
      animation: true,
      templateUrl: 'biblio/auteurbiblio-edit-modal.html',
      controller: AuteurbiblioEditModalController,
      controllerAs: 'auteurbiblioEditModalCtrl',
      resolve: {
        initialAuteurbiblio: () => {
          if (auteurbiblioId) {
            return AuteurbiblioService.load(auteurbiblioId);
          }
          return { editable: true };
        }
      }
    }).result;
  }
}

function AuteurbiblioEditModalController($scope, $modalInstance, $window, AuteurbiblioService, initialAuteurbiblio) {
  var auteurbiblioEditModalCtrl = this;

  auteurbiblioEditModalCtrl.submit = submit;
  auteurbiblioEditModalCtrl.cancel = cancel;

  init();

  $scope.$on('modal.closing', (event, reason, closed) => {
    if (closed || auteurbiblioEditModalCtrl.fullyCancelled) {
      return;
    }

    // Il faut veiller à ce que l'auteur soit correctement annulée
    event.preventDefault();

    cancel();
  });

  function submit() {
    if (!$scope.auteurBiblioForm.$valid) {
      event.preventDefault();
      auteurbiblioEditModalCtrl.error = { message: "Le formulaire n'est pas valide." };
      return;
    }

    if ($window.confirm('Attention vous allez mettre à jour des informations sur une ressource partagée et non remplacer ou supprimer  '
      + auteurbiblioEditModalCtrl.auteurbiblio.nom)) {
      auteurbiblioEditModalCtrl.closing = true;
      auteurbiblioEditModalCtrl.error = null;

      AuteurbiblioService.save(auteurbiblioEditModalCtrl.auteurbiblio)
        .then((newAuteurbiblio) => {
          auteurbiblioEditModalCtrl.closing = false;
          $modalInstance.close(newAuteurbiblio.id);
        }, (error) => {
          auteurbiblioEditModalCtrl.closing = false;
          auteurbiblioEditModalCtrl.error = error;
        });
    }
  }

  function cancel() {
    if ($scope.auteurBiblioForm.$dirty && !$window.confirm("Des données ont été modifiées et ne sont pas sauvegardées. Voulez-vous continuer ?")) {
      return;
    }

    auteurbiblioEditModalCtrl.fullyCancelled = true;
    $modalInstance.dismiss();
  }

  function init() {
    auteurbiblioEditModalCtrl.auteurbiblio = _.cloneDeep(initialAuteurbiblio.auteurbiblio);
    auteurbiblioEditModalCtrl.editable = _.cloneDeep(initialAuteurbiblio.editable);
    auteurbiblioEditModalCtrl.nbBiblio = initialAuteurbiblio.nbBiblio;
    auteurbiblioEditModalCtrl.errorMessage = null;
  }
}
