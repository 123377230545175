angular.module('app')
  .factory('PersonneEditModal', PersonneEditModal);

/*@ngInject*/
function PersonneEditModal($q, $rootScope, $modal, $window, PersonneService) {
  return { createPersonne, updatePersonne };

  function createPersonne(uniqueId, illustrationId) {
    openModal(null, illustrationId)
      .then((personneId) => {
        // TODO: A remplacer par un événement géré par PersonneService dans le cas où le template englobant est Angular et non Handlebars
        $rootScope.$broadcast('personne.edit.success', uniqueId, personneId);
      });
  }

  function updatePersonne(uniqueId, illustrationId, personne) {
    openModal(personne.id, illustrationId)
      .then((personneId) => {
        // TODO: A remplacer par un événement géré par PersonneService dans le cas où le template englobant est Angular et non Handlebars
        $rootScope.$broadcast('personne.edit.success', uniqueId, personneId);
      });
  }

  function openModal(personneId, illustrationId) {
    return $modal.open({
      animation: true,
      templateUrl: 'illustration/personne-edit-modal.html',
      controller: PersonneEditModalController,
      controllerAs: 'personneEditModalCtrl',
      resolve: {
        initialPersonne: () => {
          if (personneId) {
            return PersonneService.lockAndGet(personneId, illustrationId);
          }
          return { editable: true };
        },
        illustrationId: () => illustrationId
      }
    }).result;
  }
}

function PersonneEditModalController($scope, $modalInstance, $window, PersonneService, initialPersonne, illustrationId) {
  var personneEditModalCtrl = this;

  personneEditModalCtrl.submit = submit;
  personneEditModalCtrl.cancel = cancel;
  personneEditModalCtrl.notProcessing = notProcessing;
  personneEditModalCtrl.updatePersonneType = updatePersonneType;

  init();

  $scope.$on('modal.closing', (event, reason, closed) => {
    if (_.isEmpty(initialPersonne.personne) || closed || !personneEditModalCtrl.editable || personneEditModalCtrl.fullyCancelled) {
      return;
    }

    // Il faut veiller à ce que la personne soit correctement annulée
    event.preventDefault();

    cancel();
  });

  function updatePersonneType() {
    setPersonneType(personneEditModalCtrl.personne.type);

    if ('Physique' == personneEditModalCtrl.personne.type) {
      // On recopie les champs de morale vers physique
      personneEditModalCtrl.personne.nom = personneEditModalCtrl.personne.nom
        ? personneEditModalCtrl.personne.nom
        : personneEditModalCtrl.personne.raisonSociale;
      personneEditModalCtrl.personne.dateNaissance = personneEditModalCtrl.personne.debutActivite;
      personneEditModalCtrl.personne.dateDeces = personneEditModalCtrl.personne.finActivite;
    } else {
      // On recopie les champs de physique vers morale
      personneEditModalCtrl.personne.raisonSociale =
        [personneEditModalCtrl.personne.nom, personneEditModalCtrl.personne.prenom].join(' ');
      personneEditModalCtrl.personne.debutActivite = personneEditModalCtrl.personne.dateNaissance;
      personneEditModalCtrl.personne.finActivite = personneEditModalCtrl.personne.dateDeces;
    }
  }

  function setPersonneType(personneType) {
    personneEditModalCtrl.formTemplateUrl = 'illustration/personne-' + personneType.toLowerCase() + '-form.html';
    if (personneEditModalCtrl.personne != null) {
      personneEditModalCtrl.personne.type = personneType;
    } else {
      personneEditModalCtrl.personne = { type: personneType };
    }
  }

  function updateStatusMessage() {
    // Une personne en cours de création peut etre considérée comme étant dans le status WORKING_COPY
    if (_.isEmpty(initialPersonne.personne) || personneEditModalCtrl.personne.lockStatus == 'WORKING_COPY') {
      personneEditModalCtrl.statusMessage = null;
      return;
    }
    if (personneEditModalCtrl.personne.lockStatus == 'LOCKED') {
      personneEditModalCtrl.statusMessage = "Donnée verrouillée par " + personneEditModalCtrl.personne.lockUser;
      return;
    }
    if (personneEditModalCtrl.personne.lockStatus == 'FREE') {
      personneEditModalCtrl.statusMessage = "Donnée non verrouillée";
      return;
    }
    if (! personneEditModalCtrl.personne.id) {
      personneEditModalCtrl.statusMessage = null;
      return;
    }
    personneEditModalCtrl.statusMessage = "Status non reconnu";
    personneEditModalCtrl.editable = false;
  }

  function submit() {
    if (!$scope.personneForm.$valid) {
      event.preventDefault();
      personneEditModalCtrl.error = { message: "Le formulaire n'est pas valide." };
      return;
    }

    if ($window.confirm('Attention vous allez mettre à jour des informations sur une ressource partagée et non remplacer ou supprimer "'
    + personneEditModalCtrl.personne.nom + ' ' + personneEditModalCtrl.personne.prenom+ '"' )) {
        personneEditModalCtrl.closing = true;
        personneEditModalCtrl.error = null;

        PersonneService.save(personneEditModalCtrl.personne, illustrationId)
          .then((newPersonne) => {
            personneEditModalCtrl.closing = false;
            $modalInstance.close(newPersonne.id);
          }, (error) => {
            personneEditModalCtrl.closing = false;
            personneEditModalCtrl.error = error;
          });
    }
  }

  function cancel() {
    if ($scope.personneForm.$dirty && !$window.confirm("Des données ont été modifiées et ne sont pas sauvegardées. Voulez-vous continuer ?")) {
      return;
    }

    if (_.isEmpty(initialPersonne.personne)) {
      $modalInstance.dismiss();
      return;
    }

    personneEditModalCtrl.dismissing = true;
    personneEditModalCtrl.error = null;

    PersonneService.cancel(personneEditModalCtrl.personne.id, illustrationId)
      .then(() => {
        personneEditModalCtrl.fullyCancelled = true;
        $modalInstance.dismiss();
      }, (error) => {
        personneEditModalCtrl.dismissing = false;
        personneEditModalCtrl.error = error;
        personneEditModalCtrl.error.cancel = true;
      });
  }

  function notProcessing() {
    return !personneEditModalCtrl.dismissing && !personneEditModalCtrl.closing;
  }

  function init() {
    personneEditModalCtrl.personne = _.cloneDeep(initialPersonne.personne);
    personneEditModalCtrl.editable = _.cloneDeep(initialPersonne.editable);
    personneEditModalCtrl.nbIllustration = initialPersonne.nbIllustration;
    personneEditModalCtrl.illustrationId = illustrationId;
    personneEditModalCtrl.errorMessage = null;

    if (initialPersonne.personne != null && initialPersonne.personne.type != null) {
      setPersonneType(initialPersonne.personne.type);
    } else {
      setPersonneType("Physique");
    }
    updateStatusMessage();
  }
}
